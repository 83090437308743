import * as React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'

const NotFoundPage = () => (
    <Layout>
        <Seo title='Page Not found' />
        <h1>Page not found</h1>
        <p>
            Go to <Link to='/'>homepage</Link>
        </p>
    </Layout>
)

export default NotFoundPage
